import styles from './index.module.scss'
import {useDispatch} from "react-redux";
import Icon from "../../../common/icon";

export default function Share({productName, productUrl, photo}) {
    const dispatch = useDispatch();
    const Share = {
        vkontakte: function() {
            let url  = 'http://vkontakte.ru/share.php?';
            url += 'url='          + encodeURIComponent(productUrl);
            url += '&title='       + encodeURIComponent(productName);
            url += '&description=' + encodeURIComponent(productName);
            url += '&image='       + encodeURIComponent(photo);
            url += '&noparse=true';
            Share.popup(url);
        },
        odnoklassniki: function() {
            let url  = 'http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1';
            url += '&st.comments=' + encodeURIComponent(productName);
            url += '&st._surl='    + encodeURIComponent(productUrl);
            Share.popup(url);
        },
        facebook: function() {
            let url  = 'http://www.facebook.com/sharer.php?s=100';
            url += '&p[title]='     + encodeURIComponent(productName);
            url += '&p[summary]='   + encodeURIComponent(productName);
            url += '&p[url]='       + encodeURIComponent(productUrl);
            url += '&p[images][0]=' + encodeURIComponent(photo);
            Share.popup(url);
        },
        twitter: function() {
            let url  = 'http://twitter.com/share?';
            url += 'text='      + encodeURIComponent(productName);
            url += '&url='      + encodeURIComponent(productUrl);
            url += '&counturl=' + encodeURIComponent(productUrl);
            Share.popup(url);
        },
        mailru: function() {
            let url  = 'http://connect.mail.ru/share?';
            url += 'url='          + encodeURIComponent(productUrl);
            url += '&title='       + encodeURIComponent(productName);
            url += '&description=' + encodeURIComponent(productName);
            url += '&imageurl='    + encodeURIComponent(photo);
            Share.popup(url)
        },
        telegram: function () {
            let url = 'https://t.me/share/url?';
            url += 'url=' + window.location.href;
            url += `&text=${productName}`
            url += '&utm_source=share2'

            Share.popup(url)
        },
        whatsapp: function () {
            let url = 'https://api.whatsapp.com/send?';
            url += `text=${productName} ${window.location.href}`
            url += '&utm_source=share2'

            Share.popup(url)
        },
        popup: function(url) {
            window.open(url,'','toolbar=0,status=0,width=626,height=436');
	}
};
    return <div className={styles.Share}>
        <span>Поделиться:</span>
        <div>

            <a onClick={Share.vkontakte}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.9669 19C5.4704 19 1.13755 13.75 1 5H4.78265C4.9202 11.44 7.67122 14.1 9.87203 14.66V5H13.4484V10.53C15.6492 10.32 17.9188 7.8 18.6753 5H22.2516C21.6326 8.43 19.1567 10.95 17.4373 12C19.2255 12.84 22.0453 15.01 23.0769 19H19.1567C18.3314 16.34 16.1994 14.31 13.4484 14.03V19H12.9669Z"
                        fill="#9294A9"/>
                </svg>
            </a>
            <a onClick={Share.telegram}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M19.8105 4.08692C18.5394 4.5573 2.873 10.6115 2.64225 10.7216C1.8755 11.0871 1.78586 11.57 2.42417 11.8966C2.48579 11.9281 3.5462 12.2692 4.78061 12.6546L7.02504 13.3553L12.1214 10.1484C14.9244 8.38456 17.2743 6.92163 17.3432 6.89747C17.5163 6.83676 17.6653 6.84243 17.7348 6.91239C17.7835 6.96134 17.7855 6.98591 17.7468 7.05786C17.7212 7.10546 15.8599 8.80584 13.6105 10.8365C11.3611 12.8671 9.5146 14.5444 9.5072 14.5639C9.4998 14.5833 9.4237 15.5971 9.3381 16.8168L9.1825 19.0343L9.3516 19.0114C9.4446 18.9988 9.5918 18.9505 9.6788 18.9041C9.7658 18.8577 10.3515 18.3241 10.9802 17.7183C11.609 17.1125 12.1452 16.6084 12.1719 16.5981C12.1984 16.5879 13.1998 17.3013 14.397 18.1836C15.5942 19.0659 16.6675 19.8314 16.7821 19.8847C17.0829 20.0245 17.4217 20.0383 17.6417 19.9199C17.8382 19.8141 18.0303 19.5513 18.1111 19.2777C18.1424 19.1718 18.8268 15.9907 19.6321 12.2085C20.9729 5.91143 21.0963 5.30177 21.0968 4.97494C21.0972 4.66614 21.0844 4.59269 21.0022 4.43016C20.9442 4.31557 20.8453 4.19858 20.7486 4.1302C20.5276 3.97383 20.1617 3.95696 19.8105 4.08692Z"
                          fill="#9294A9"/>
                </svg>
            </a>
            <a onClick={Share.whatsapp}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M19.3775 5.6158C17.6854 3.92988 15.4351 3.00095 13.0376 3C8.09763 3 4.07712 7.00098 4.07515 11.9188C4.07449 13.4909 4.4872 15.0254 5.27146 16.378L4 21L8.75112 19.7597C10.0601 20.4702 11.534 20.8447 13.034 20.8453H13.0377C17.9771 20.8453 21.998 16.8439 22 11.926C22.001 9.5427 21.0696 7.30171 19.3775 5.6158ZM17.1237 13.7866C16.8997 13.6751 15.7988 13.1359 15.5935 13.0615C15.3882 12.9872 15.239 12.95 15.0896 13.1731C14.9404 13.3962 14.5112 13.8982 14.3805 14.0469C14.2499 14.1957 14.1193 14.2143 13.8954 14.1027C13.6714 13.9912 12.9499 13.7558 12.0945 12.9966C11.4288 12.4056 10.9793 11.6758 10.8487 11.4527C10.7181 11.2295 10.8348 11.1089 10.9469 10.9978C11.0476 10.8979 11.1709 10.7375 11.2828 10.6074C11.3948 10.4772 11.4321 10.3843 11.5067 10.2355C11.5814 10.0868 11.5441 9.95662 11.4881 9.84507C11.4321 9.73353 10.9843 8.63655 10.7976 8.19033C10.6159 7.75575 10.4312 7.81455 10.2938 7.80775C10.1633 7.80129 10.0139 7.7999 9.86459 7.7999C9.7153 7.7999 9.4727 7.85569 9.26741 8.07878C9.06216 8.30191 8.48367 8.84109 8.48367 9.93804C8.48367 11.035 9.28609 12.0947 9.39806 12.2435C9.51002 12.3922 10.9771 14.6433 13.2236 15.6087C13.7578 15.8383 14.175 15.9754 14.5002 16.0782C15.0367 16.2478 15.5249 16.2238 15.9107 16.1665C16.341 16.1025 17.2356 15.6273 17.4223 15.1067C17.6089 14.5861 17.6089 14.1399 17.5529 14.0469C17.4969 13.954 17.3476 13.8982 17.1237 13.7866Z"
                          fill="#9294A9"/>
                </svg>
            </a>
        </div>
    </div>
}