import Button from "/components/common/button";
import Icon from "../../../common/icon";
import {useDispatch, useSelector} from "react-redux";
import {setShowTopSections} from "/redux/action-creaters/pageState";
import styles from './index.module.scss'
import clsx from "clsx";

export default function Catalog({isScrolled}) {
    const dispatch = useDispatch();
    const {isShowTopSections} = useSelector(state => state.pageState)

    const toggleTopCatalog = () => {
        dispatch(setShowTopSections(!isShowTopSections))
    }

    return (
        <Button
            label='Каталог'
            onClick={toggleTopCatalog}
            type={'black'}
            before={
                <Icon id={isShowTopSections ? 'close' : 'burgergrid'} fill={isShowTopSections ? '#fff' : '#fff'} width={22} height={22}/>
            }
            customStyles={clsx(styles.CatalogButton, isScrolled && styles.CatalogButtonScrolled)}
        />
    )
}