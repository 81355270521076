import {useSelector} from "react-redux";
import styles from './index.module.scss'
import {Api} from "/api";
import {useEffect, useState} from "react";

export default function Manager({productId}) {
    const [managerComment, setManagerComment] = useState('')
    const [managerData, setManagerData] = useState('')
    const {user} = useSelector(state => state.user)
    useEffect(() => {
        if (user.IS_MANAGER) {
            const id = productId;
            const getComments = async () => {
                const comment = await Api.catalog.getManagerComments([id]);
                setManagerComment(comment[id])
            }
            const getData = async () => {
                const managerData = await Api.catalog.getManagerData([id])
                setManagerData(managerData[id])
            }

            getComments();
            getData();
        }
    }, [user, productId])

    return (
        <>
            {Boolean(managerComment) && (
                <p style={{color: 'red'}}>{managerComment}<br/></p>
            )}
            {Boolean(managerData) && (
                <div className={styles.ManagerData}
                     dangerouslySetInnerHTML={{__html: managerData}}/>
            )}
        </>
    )
}