const initialState = {
    product: {},
    photos: {},
    reviews: [],
    questions: [],
    brand: {},
    sert: {},
    qestionsAndReviewsCount: {},
    isDeliveryLoading: false,
    delivery: {},
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCT':
            return {...state, product: action.payload}
        case 'FETCH_PHOTOS':
            return {...state, photos: action.payload}
        case 'FETCH_REVIEWS':
            return {...state, reviews: action.payload}
        case 'FETCH_QUESTIONS':
            return {...state, questions: action.payload}
        case 'FETCH_BRAND':
            return {...state, brand: action.payload}
        case 'FETCH_SERT':
            return {...state, sert: action.payload}
        case 'SET_DELIVERY_LOADING':
            return {...state, isDeliveryLoading: action.payload}
        case 'FETCH_DELIVERY':
            return {...state, delivery: action.payload}
        default:
            return state
    }
}