import {Api} from "../../api";

export const fetchProduct = (code, host, reset = false) => {
    return async (dispatch) => {
        const response = await Api.server.getProduct(code, host, reset)
        dispatch({type: 'FETCH_PRODUCT', payload: response})
    }
}

export const fetchReviews = (productId, host, isReset = false) => {
    return async (dispatch) => {
        const response = await Api.server.getProductReviews(productId, host, isReset)
        dispatch({type: 'FETCH_REVIEWS', payload: response})
    }
}

export const fetctQuestions = (productId, host, isReset = false) => {
    return async (dispatch) => {
        const response = await Api.server.getProductQuestions(productId, host, isReset)
        dispatch({type: 'FETCH_QUESTIONS', payload: response})
    }
}

export const fetchDeliveryInfo = (productId) => {
    return async (dispatch) => {
        dispatch({type: 'SET_DELIVERY_LOADING', payload: true})
        const response = await Api.delivery.getProductsDeliveryNew(productId)
        dispatch({type: 'FETCH_DELIVERY', payload: response})
        dispatch({type: 'SET_DELIVERY_LOADING', payload: false})
    }
}
