import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {Api} from "../../../../api";
import {useRouter} from "next/router";
import Icon from '../../../common/icon';

export default function Services({sectionId, marka, isLanding}) {
    const {cityName} = useSelector(state => state.location)
    const [services, setServices] = useState([])
    const [servicesInstall, setServicesInstall] = useState([])
    const router = useRouter();

    useEffect(async () => {

        if (!isLanding) {
            if (sectionId != 635) {
                return
            }
            if  (cityName !== 'Москва' && cityName !== 'Санкт-Петербург') {
                return
            }
        }

        const services = await Api.server.getServices(Boolean(router.query.reset));
        setServices(services)
        if (marka) {
            const servicesInstall = await Api.server.getServicesInstall(marka, Boolean(router.query.reset));
            setServicesInstall(servicesInstall)
        }
    }, [cityName])

    const servicesFiltered = useMemo(() => {
        const isMarkInstallExist = servicesInstall.length > 0;
        let items = services.filter(service => {
            if (isMarkInstallExist && service.NAME.includes('Установка')) {
                return false;
            }
            if (service.NAME.includes('Сертификация')) {
                return false
            }
            if (service.PROPERTY_CITYS_VALUE.includes(cityName)) {
                return true
            }
            return false
        });

        return [...servicesInstall, ...items];
    }, [services, servicesInstall, cityName])

    return services?.length > 0 && (
        <div className={ isLanding ? styles.ServicesContainerLanding : '' }>
            <div className={styles.Title}>{isLanding ? 'Услуги наших партнеров' : 'Услуги'}</div>
            <div className={styles.Services}>
                {servicesFiltered.map(item => (
                    <>
                        <a key={item.ID} href={item.DETAIL_PAGE_URL}>
                            {isLanding && 
                                <div className={styles.ArrowLanding}>
                                    <Icon id='chevron_right' fill={'#FFFFFF'} width={20} height={20}/>
                                </div>
                            }
                            <div>
                                <span className={styles.Name}>{item.NAME}</span>
                                <span className={styles.Text} dangerouslySetInnerHTML={{__html: item.PREVIEW_TEXT}}></span>
                                {!isLanding && 
                                    <span className={styles.Link}>Подробнее</span>
                                }
                            </div>
                            <span className={styles.ImgLanding}><img src={item.PRODUCT_PICTURE_SRC}/></span>
                        </a>
                    </>
                ))}
            </div>
        </div>
    )
}