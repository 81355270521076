import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {showPopup} from "/redux/action-creaters/popup";
import {popupTypes} from "/redux/reducers/popupReducer";
import {useEffect, useMemo, useState} from "react";
import {declOfNum} from "/utils";
import {fetchDomains, getDeliveryCityData} from "/redux/action-creaters/location";
import Link from 'next/link'
import Menu from "../menu";
import Search from "/components/layouts/landings/search"
import Catalog from "/components/layouts/landings/catalog"
import Button from "/components/common/button"
import Cart from "/components/layouts/baltex/cart"
import User from "/components/layouts/baltex/user/"
import Icon from "/components/common/icon";
import React from "react";
import clsx from "clsx";
import {setShowMobileMenu} from "/redux/action-creaters/pageState";
import {setLocation} from "/redux/action-creaters/location";
import Cookies from 'js-cookie'
import {useRouter} from "next/router";
import {Api} from "../../../../api";

export default React.memo(function Header({contactsData, user, basket, isScrolled, isMain, isShowTopSections, isLanding = false}) {
    const dispatch = useDispatch();
    const {cityName, deliveyCityData, domains} = useSelector(state => state.location)
    const {isShowMobileMenu} = useSelector(state => state.pageState)
    const [searchActivate, setSearchActive] = useState(0);
    const [detectedCity, setDetectedCity] = useState(null);
    const {
        edostMinDays,
    } = useSelector(state => state.location)

    useEffect(() => {
        if (typeof window !== 'object') {
            return
        }

        if (!cityName) {
            getCity();
        }
    }, [cityName])

    const getCity = () => {
        let cookieCityName = Cookies.get('cityName');
        const isRobot = window.navigator?.userAgent?.includes('yandex') || window.navigator?.userAgent?.includes('google');
        if (!cookieCityName && !isRobot) {
            dispatch(setLocation('Санкт-Петербург'));
            detectCity();
        } else {
            dispatch(setLocation(cookieCityName));
        }
    }

    const detectCity = async () => {
        const detectedCity = await Api.dadata.detectCity();
        if (cityName !== detectedCity?.['city']) {
            setDetectedCity(detectedCity)
        }
    }

    useEffect(() => {
        const body = document.querySelector("body");
        if (!detectedCity) {
            body.classList.remove("popup");
        } else {
            body.classList.add("popup");
        }
    }, [detectedCity]);

    const phone = useMemo(() => {
        setTimeout(() => {
            if (typeof window === 'object') {
                window?.ct_replace_495 && window?.ct_replace_495()
                window?.ct_replace_812 && window?.ct_replace_812()
                window?.ct_replace_800 && window?.ct_replace_800()
            }
        }, 500);
        return contactsData?.PHONE
    }, [contactsData])

    const deliveryInfo = useMemo(() => {
        if (!contactsData) {
            return ''
        }
        if (contactsData['NAME'] === 'Центральный') {
            if (edostMinDays > 0) {
                return `Доставка от ${declOfNum((edostMinDays), ['дня', 'дней', 'дней'])}`
            }
            return ''
        }
        return declOfNum(contactsData.SHOPS?.length, ['магазин', 'магазина', 'магазинов'])
    }, [contactsData, edostMinDays])


    const router = useRouter();
    const isCheckoutPage = useMemo(() => {
        if (router.asPath.indexOf('/personal/cart/') > -1) {
            return true
        }
        return false
    }, [router])

    return (
        <header>
            {detectedCity?.city && (
                <noindex>
                    <div className={styles.Wrapper} onClick={() => {
                        setDetectedCity(null)
                    }}>
                        <div onClick={e => e.stopPropagation()} className={styles.DetectedPopup}>
                            <div className={styles.DetectedCity}>Ваш город {detectedCity.city}?</div>
                            <div className={styles.DetectedButtons}>
                                <Button onClick={async () => {
                                    await dispatch(getDeliveryCityData(detectedCity, true))
                                    await dispatch(setLocation(detectedCity.city));

                                    setDetectedCity('');
                                }} label={'Да, верно'}/>
                                <Button
                                    onClick={() => {
                                        dispatch(showPopup(popupTypes.locationNew))
                                        setDetectedCity('')
                                    }}
                                    type={'outline'}
                                    label={'Выбрать другой'}
                                />
                            </div>
                        </div>
                    </div>
                </noindex>
            )}
            <div className='container'>
                <div className={clsx(styles.HeaderWpapper, isLanding && styles.HeaderWpapperLanding)}>
                        <div className={styles.Header}>
                            <div className={isLanding ? styles.ContainerLanding : 'container'}>
                                <div className={clsx(styles.Top, isScrolled && styles.TopScrolled, isShowTopSections && styles.TopCatalog, isShowMobileMenu && styles.TopMobiled, isMain && styles.TopMain)}>
                                    <div>
                                        <div className={styles.TopLeft}>
                                            <div className={styles.Location} onClick={() => dispatch(showPopup(popupTypes.locationNew))}>
                                                <div className={styles.City}>

                                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M7 18C5.73693 16.9227 4.56619 15.7416 3.5 14.4691C1.9 12.5581 8.83662e-07 9.71205 8.83662e-07 7.00005C-0.00141728 4.1676 1.70425 1.61344 4.32107 0.529448C6.93789 -0.554545 9.95007 0.0452907 11.952 2.04905C13.2685 3.35966 14.0059 5.14244 14 7.00005C14 9.71205 12.1 12.5581 10.5 14.4691C9.43382 15.7416 8.26307 16.9227 7 18ZM7 4.00005C5.92821 4.00005 4.93782 4.57185 4.40193 5.50005C3.86603 6.42825 3.86603 7.57185 4.40193 8.50005C4.93782 9.42825 5.92821 10.0001 7 10.0001C8.65686 10.0001 10 8.6569 10 7.00005C10 5.3432 8.65686 4.00005 7 4.00005Z"/>
                                                    </svg>
                                                    {cityName}
                                                    <span style={{color: 'green'}}>{deliveryInfo && `(${deliveryInfo})`}</span>

                                                </div>
                                            </div>
                                            <Menu cityName={cityName}/>
                                        </div>
                                        <div className={styles.Phone}>
                                            <a href={`tel:${phone}`} className={contactsData.CALL_TOUCH_CODE} onClick={() => {

                                            }}>

                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.9975 16C6.46587 16.0121 -0.00911762 9.4595 9.63742e-06 1.00251C9.63742e-06 0.450225 0.447725 0 1.00001 0H3.63937C4.13494 0 4.55593 0.364129 4.6287 0.854329C4.80311 2.0292 5.14517 3.17297 5.64448 4.2507L5.74725 4.4725C5.89013 4.78091 5.79328 5.14734 5.51668 5.34487C4.6993 5.92859 4.38692 7.10355 5.0237 8.02036C5.82278 9.17084 6.83005 10.1779 7.98027 10.9766C8.89702 11.6132 10.0718 11.3009 10.6555 10.4836C10.8532 10.2069 11.2198 10.11 11.5284 10.2529L11.7492 10.3552C12.827 10.8545 13.9708 11.1966 15.1458 11.371C15.636 11.4437 16 11.8647 16 12.3603V15C16 15.5523 15.5512 16 14.9989 16L14.9975 16Z"/>
                                                </svg>

                                                {phone}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.BodyWrapper}>
                                    <div className={clsx(styles.Body, isScrolled && styles.BodyFixed, isShowMobileMenu && styles.BodyMobileMenu)}>
                                        <div>
                                            <div className={clsx(styles.Icons, styles.IconsMobile)}>
                                                <div
                                                    className={clsx(styles.Menu, isShowMobileMenu && styles.MenuActive)}
                                                    onClick={() => {
                                                        dispatch(setShowMobileMenu(!isShowMobileMenu))
                                                    }}
                                                >
                                                    <div>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <Button
                                                    type={'none'}
                                                    onClick={() => { setSearchActive(i => i + 1) }}
                                                    before={
                                                        <Icon id='search' stroke={'#9294A9'} strokeWidth={2} size={24}/>
                                                    }
                                                    customStyles={clsx(styles.ButtonMobile, isShowMobileMenu && styles.ButtonMobileActive)}
                                                    isLanding={isLanding}
                                                />
                                            </div>
                                            <Link href="/">
                                                <a className={styles.Logo}>
                                                    <img src="/baltex/LOGOTYPE.svg"/>
                                                </a>
                                            </Link>
                                            <Catalog isScrolled={isScrolled} isLanding={isLanding}/>
                                            <div className={clsx(styles.Icons, isShowMobileMenu && styles.IconsMenuActive, isScrolled && styles.IconsScrolled)}>
                                                <Search
                                                    activate={searchActivate}
                                                    isScrolled={isScrolled}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.BodyWrapperMobile}>
                                    <div className={clsx(styles.Body, isScrolled && styles.BodyFixed, isShowMobileMenu && styles.BodyMobileMenu, isLanding && styles.BodyLanding)}>
                                        <div className={clsx(isLanding && styles.LogoContainerLanding)}>
                                            <Link href="/">
                                                <a className={styles.Logo}>
                                                    <img src="/baltex/LOGOTYPE.svg"/>
                                                </a>
                                            </Link>
                                        </div>
                                        <div className={clsx(isLanding && styles.IconsContainerLanding)}>
                                            <div className={clsx(styles.Icons, styles.IconsMobile, isLanding && styles.IconsLanding)}>
                                                <div
                                                    className={clsx(styles.Menu, isShowMobileMenu && styles.MenuActive)}
                                                    onClick={() => {
                                                        dispatch(setShowMobileMenu(!isShowMobileMenu))
                                                    }}
                                                >
                                                    <div>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <Button
                                                    type={'none'}
                                                    onClick={() => { setSearchActive(i => i + 1) }}
                                                    before={
                                                        <Icon id='search' stroke={'#9294A9'} strokeWidth={2} size={24}/>
                                                    }
                                                    customStyles={clsx(styles.ButtonMobile, isShowMobileMenu && styles.ButtonMobileActive)}
                                                    isLanding={isLanding}
                                                />
                                            </div>
                                            
                                            <div className={styles.CatalogIconMobile}>
                                                <Button
                                                    onClick={() => {
                                                        dispatch(setShowMobileMenu(!isShowMobileMenu))
                                                    }}
                                                    type={isShowTopSections ? 'blackSelected' : 'blue'}
                                                    before={
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="4" y="4" width="3" height="3" fill="white"/>
                                                            <rect x="4" y="10" width="3" height="3" fill="white"/>
                                                            <rect x="4" y="16" width="3" height="3" fill="white"/>
                                                            <rect x="10" y="4" width="3" height="3" fill="white"/>
                                                            <rect x="10" y="10" width="3" height="3" fill="white"/>
                                                            <rect x="10" y="16" width="3" height="3" fill="white"/>
                                                            <rect x="16" y="4" width="3" height="3" fill="white"/>
                                                            <rect x="16" y="10" width="3" height="3" fill="white"/>
                                                            <rect x="16" y="16" width="3" height="3" fill="white"/>
                                                        </svg>
                                                    }
                                                    customStyles={clsx(styles.CatalogButton, isScrolled && styles.CatalogButtonScrolled, isLanding && styles.CatalogButtonLanding)}
                                                    isLanding={isLanding}
                                                />
                                            </div>
                                            <div className={clsx(styles.Icons, isShowMobileMenu && styles.IconsMenuActive, isScrolled && styles.IconsScrolled, isLanding && styles.IconsSearchLanding)}>
                                                <Search
                                                    activate={searchActivate}
                                                    isScrolled={isScrolled}
                                                    isLanding={isLanding}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </header>
    )
})