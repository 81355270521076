import styles from './index.module.scss'
import Icon from "../../../common/icon";
import clsx from 'clsx';

export default function Files({file, isBaltex = false, isLanding = false}) {
    return file && <div className={clsx(styles.Files, isLanding && styles.FilesLanding)}>
        <a href={file} className={styles.File} rel="noreferrer" target="_blank">
            {isLanding && (
                <Icon id='download' width={18} height={20}/>
            )}
            {isBaltex && (
                <Icon id='baltexpdf' width={18} height={20}/>
            )}
            {!isLanding && !isBaltex && (
                <Icon fill='#1247a5' id={'pdf'} width={18} height={20}/>
            )}
            <div className={styles.Name}>
                <div>Паспорт (инструкция)</div>
            </div>
        </a>
    </div>
}